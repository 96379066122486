const EMesureAction = {
  UPDATE_SELECTED_MESURE: 'UPDATE_SELECTED_MESURE',
  UPDATE_SELECTED_OPERATION_BANCAIRE: 'UPDATE_SELECTED_OPERATION_BANCAIRE',
  UPDATE_SELECTED_DETAIL: 'UPDATE_SELECTED_DETAIL',
  RESET_MESURE: 'RESET_MESURE',
  RESET_OPERATION_BANCAIRE: 'RESET_OPERATION_BANCAIRE',
  RESET_DETAIL: 'RESET_DETAIL'
};

export default EMesureAction;
