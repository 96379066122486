import dessinJuge from '../img/dessin_juge.jpg';
import logo from '../img/logoReqornot.png';
import majeur from '../img/majeur.png';
import ati from '../img/logoATI.jpg';
import juge from '../img/tribunal.png';

export const imgDessinJuge = {
  src: dessinJuge,
  alt: 'dessin animé d\'un juge'
};

export const logoSite = {
  src: logo,
  alt: 'logo du site ReqOrNot'
};

export const imgMajeur = {
  src: majeur,
  alt: 'icône majeur'
};

export const imgAti = {
  src: ati,
  alt: 'logo de l\'ATI'
};

export const imgJuge = {
  src: juge,
  alt: 'icône d\'un tribunal'
};
